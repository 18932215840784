import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckboxIcon } from '@/assets/svg/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@/assets/svg/checkbox-checked.svg';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function Checkbox({ checked, ...other }: Props) {
  return (
    <Component>
      {checked ? <CheckboxCheckedIcon /> : <CheckboxIcon />}
      <Input type="checkbox" checked={checked} {...other} />
    </Component>
  );
}

export default Checkbox;

const Component = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  cursor: inherit;
`;
