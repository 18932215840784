import React from 'react';
import styled, { css } from 'styled-components';

import ButtonIcon from '@/components/ButtonIcon';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';
import { media } from '@/utils/mixin';

interface Props {
  closeModal: () => void;
}

function MobileProductInfoPlaceholder({ closeModal }: Props) {
  return (
    <Inner>
      <Icon icon={'close'} onClick={closeModal} />
      <>
        <HeaderBlock>
          <HeaderContent />
          <AddToCard />
          <TitleBlock>
            <Title />
            <CategoriesValueBlock />
          </TitleBlock>
        </HeaderBlock>
        <ContentBlock>
          <TitlePrice />
          <Description />
          <Description />
          <Description />
          <Category>
            <SubTitle />
            <Technology />
          </Category>
          <Category>
            <SubTitle />
            <Technology />
          </Category>
        </ContentBlock>
      </>
    </Inner>
  );
}

export default MobileProductInfoPlaceholder;

const HeaderBlock = styled.div`
  border-bottom: solid 1px #eceef3;
  padding: 15px 15px 20px;
`;

const TitleBlock = styled.div`
  margin-top: 30px;
`;

const HeaderContent = styled.div`
  position: relative;
  ${customPlaceholder};
  width: 294px;
  height: 456px;
  margin: 0 auto 30px;
  border-radius: 20px;
  overflow: hidden;
  ${media.mobileMedium(css`
    width: 255px;
  `)}${media.mobileSmall(css`
    width: 200px;
  `)}
`;

const Title = styled.div`
  ${customPlaceholder};
  margin-bottom: 9px;
  width: 170px;
  height: 30px;
`;

const TitlePrice = styled.div`
  ${customPlaceholder};
  margin-bottom: 9px;
  width: 70px;
  height: 30px;
`;

const Icon = styled(ButtonIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Inner = styled.div<{ width?: number }>`
  position: relative;
  background-color: #fff;
  z-index: 11;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
`;

const ContentBlock = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  width: 80px;
  height: 20px;
  ${customPlaceholder};
  margin-bottom: 5px;
`;

const Category = styled.div`
  margin-bottom: 15px;
`;

const SubTitle = styled.div`
  width: 80px;
  height: 20px;
  ${customPlaceholder};
  margin-bottom: 5px;
`;

const CategoriesValueBlock = styled.div`
  ${customPlaceholder};
  width: 62px;
  height: 20px;
  margin-bottom: 5px;
`;

const Technology = styled.div`
  width: 232px;
  height: 24px;
  ${customPlaceholder};
`;

const AddToCard = styled.div`
  width: 100%;
  margin: auto 0px 0px;
  border-radius: 20px;
  height: 40px;
  ${customPlaceholder};
`;
