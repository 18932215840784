import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { useTypedSelector } from '@/store/store';
import { getCategoryList } from '@/store/reducers/catalog';
import { getCategoryUrlAliasFromRouterQuerySlug } from '@/utils/common';
import { ReactComponent as CloseIcon } from '@/assets/svg/close_24dp.svg';

import ApplySection from './components/AsideFooter';
import Subcategories from './components/Subcategories';
import Features from './components/Features';
import Technologies from './components/Technologies';
import Platforms from './components/Platforms';

interface Props {
  onDrawerClose?: () => void;
  className?: string;
}

function Aside({ onDrawerClose, className }: Props) {
  const router = useRouter();
  const categoryList = useTypedSelector(getCategoryList);

  function getLabel(): string {
    const defaultValue = 'All';
    if (router.query.slug) {
      const slug = getCategoryUrlAliasFromRouterQuerySlug(
        router.query.slug,
        defaultValue
      );
      const selectedCategory = categoryList.find(
        (selectedCategory) => selectedCategory.urlAlias === slug
      );
      return selectedCategory && selectedCategory.name
        ? selectedCategory.name
        : defaultValue;
    }
    return defaultValue;
  }

  const label = getLabel();

  return (
    <Component className={className}>
      <Sticky>
        <ComponentContainer>
          <Header>
            <Label>{label}</Label>
            <Close type="button" onClick={onDrawerClose}>
              <CloseIcon />
            </Close>
          </Header>
          <Scrollbar>
            <Technologies />
            <Platforms />
            <Subcategories />
            <Features />
          </Scrollbar>
          <ApplySection onDrawerClose={onDrawerClose} />
        </ComponentContainer>
      </Sticky>
    </Component>
  );
}

export default Aside;

const Component = styled.aside`
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (max-width: 992px) {
    display: none;
  }

  @media (min-width: 992px) {
    position: relative;

    opacity: 1;
    visibility: visible;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const Sticky = styled.div`
  @media (min-width: 992px) {
    position: sticky;
    top: calc(var(--header-height) + 20px);
  }
`;

const ComponentContainer = styled.div`
  background: #fff;
  box-shadow: -4px 0 8px rgba(61, 47, 155, 0.06);

  display: grid;
  grid-template-rows: auto 1fr auto;

  @media (max-width: 992px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  @media (min-width: 992px) {
    max-height: calc(100vh - var(--header-height) - 40px);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 11px;

  padding: 11px 35px 0 35px;

  @media (max-width: 992px) {
    padding-top: 30px;
  }
`;

const Label = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #0d0735;
`;

const Close = styled.button`
  display: none;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  svg {
    fill: rgb(13, 7, 53);
  }

  border-radius: 4px;
  background-color: #e2e7f1;

  @media (max-width: 992px) {
    display: flex;
  }
`;

const Scrollbar = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  @media (max-width: 992px) {
    padding: 0 35px 0 35px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media (min-width: 992px) {
    padding: 0 15.5px 0 35px;
    margin-right: 15.5px;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);

        &:hover {
          background-color: rgba(144, 147, 153, 0.5);
        }
      }
    }

    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(144, 147, 153, 0.3) #fff;

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.375rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }
`;
