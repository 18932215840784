import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { useModal } from '@tager/web-components';

import { ReactComponent as ArrowRightIcon } from '@/assets/svg/arrow-right-see-more.svg';
import { ProductModel } from '@/typings/model';
import Link from '@/components/Link';
import CardProduct from '@/components/CardProduct';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getProductByCategoryAliasAndProductAliasThunk,
  getProducts,
} from '@/store/reducers/catalog';
import {
  getCategoryAliasFromRouterQuerySlug,
  getProductAliasFromRouterQuerySlug,
} from '@/utils/common';
import ProductInfoModal from '@/components/modals/ProductInfoModal';

interface Props {
  title: string;
  seeMoreLink: string;
  products: Array<ProductModel>;
}

function Section({ title, seeMoreLink, products }: Props) {
  const openModal = useModal();
  const router = useRouter();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const categoryAlias = getCategoryAliasFromRouterQuerySlug(
      router.query.slug
    );
    const productAlias = getProductAliasFromRouterQuerySlug(router.query.slug);

    if (categoryAlias && productAlias) {
      openModal(ProductInfoModal, {
        categoryAlias,
        productAlias,
        pathname: categoryAlias,
      });
    }
  }, [openModal, router.query.slug]);

  function handleProductClick(index: number) {
    const categoryAlias = products[index].categoryAlias;
    const productAlias = products[index].urlAlias;

    dispatch(
      getProductByCategoryAliasAndProductAliasThunk({
        categoryAlias,
        productAlias,
      })
    );

    router.push(
      {
        pathname: `/${categoryAlias}/${productAlias}`,
        query: { ...omit(router.query, ['slug']), isq: true },
      },
      undefined,
      {
        shallow: true,
      }
    );

    openModal(ProductInfoModal, {
      categoryAlias: categoryAlias,
      productAlias: productAlias,
      pathname: getCategoryAliasFromRouterQuerySlug(router.query.slug),
    });
  }

  return (
    <Component>
      <Header>
        <Title>{title}</Title>
        {products.length > 5 && (
          <SeeMore
            to={{ href: { pathname: seeMoreLink, query: { ...router.query } } }}
          >
            <SeeMoreText>See more</SeeMoreText>
            <SeeMoreIcon>
              <ArrowRightIcon />
            </SeeMoreIcon>
          </SeeMore>
        )}
      </Header>
      <ProductList>
        {products.slice(0, 5).map((product, index) => (
          <CardProduct
            key={index}
            {...product}
            onClick={() => handleProductClick(index)}
          />
        ))}
      </ProductList>
    </Component>
  );
}

export default Section;

const Component = styled.section`
  margin: 15px 0 45px 0;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 12px;
  margin-bottom: 12px;

  border-bottom: 1px solid #000;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;

  color: #0d0735;
`;

const SeeMoreIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 6px;

  transition: transform 0.15s ease-in-out;
`;

const SeeMore = styled(Link)`
  display: flex;
  align-items: center;

  font-weight: 900;
  font-size: 18px;
  line-height: 22px;

  color: #0d0735;

  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.8;

    ${SeeMoreIcon} {
      transform: translateX(4px);
    }
  }
`;

const SeeMoreText = styled.span``;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
