import React from 'react';
import styled from 'styled-components';

import { ReactComponent as PrevIcon } from '@/assets/svg/controls-i-page-prev.svg';

interface Props {
  label?: string;
}

function Previous({ label = 'Previous Page' }: Props) {
  return (
    <Component type="button">
      <Icon>
        <PrevIcon />
      </Icon>
      <Label>{label}</Label>
    </Component>
  );
}

export default Previous;

const Icon = styled.span`
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;

  transition: 0.3s;
`;

const Component = styled.button`
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;

  font-weight: 900;

  color: #0d0735;

  transition: 0.3s;

  &:hover {
    opacity: 0.8;

    ${Icon} {
      transform: translateX(-6px);
    }
  }
`;

const Label = styled.span``;
