import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { useUpdateEffect } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { OptionType } from '@/typings/common';
import {
  getPlatformList,
  getSelectedPlatforms,
  getSelectedTechnologies,
  setSelectedPlatforms,
} from '@/store/reducers/catalog';
import { getCategoryAliasFromRouterQuerySlug } from '@/utils/common';

import Section from '../Section';
import Filter from '../Filter';

import {
  applyIconToPlatform,
  convertPlatformListToOptionList,
} from './Platforms.helpers';

function Platforms() {
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const platformsList = useTypedSelector(getPlatformList);
  const optionList = convertPlatformListToOptionList(platformsList);
  const categoryAlias = getCategoryAliasFromRouterQuerySlug(router.query.slug);
  const selectedPlatforms = useTypedSelector((state) =>
    getSelectedPlatforms(state, categoryAlias ?? '/')
  );
  const selectedTechnologies = useTypedSelector(getSelectedTechnologies);
  const isVideoFaceAr = selectedTechnologies.find(
    (technologies) => technologies.value === 'videoFaceAr'
  );
  const isVideoPostProcessing = selectedTechnologies.find(
    (technologies) => technologies.value === 'videoPostProcessing'
  );

  useUpdateEffect(() => {
    if (!isVideoFaceAr && isVideoPostProcessing) {
      dispatch(
        setSelectedPlatforms({
          categoryAlias: categoryAlias ?? '/',
          data: selectedPlatforms.filter((platform) =>
            ['IOS', 'ANDROID'].includes(platform.value)
          ),
        })
      );
    }
  }, [isVideoFaceAr, isVideoPostProcessing]);

  function isSelected(platform: OptionType): boolean {
    return selectedPlatforms.some(
      (selected) => selected.value === platform.value
    );
  }

  function handleFilterChange(platform: OptionType) {
    const isChecked: boolean = isSelected(platform);

    const newSelectedPlatforms: Array<OptionType> = isChecked
      ? selectedPlatforms.filter(
          (selected) => selected.value !== platform.value
        )
      : [...selectedPlatforms, platform];

    dispatch(
      setSelectedPlatforms({
        categoryAlias: categoryAlias ?? '/',
        data: newSelectedPlatforms,
      })
    );
  }

  return (
    <Section label="Platforms">
      <FilterList>
        {optionList.map((platform, index) => (
          <Filter
            icon={applyIconToPlatform(platform.value)}
            key={index}
            label={platform.label}
            checked={isSelected(platform)}
            onChange={() => handleFilterChange(platform)}
            disabled={
              !isVideoFaceAr && isVideoPostProcessing
                ? !['IOS', 'ANDROID'].includes(String(platform.value))
                : false
            }
          />
        ))}
      </FilterList>
    </Section>
  );
}

export default React.memo(Platforms);

const FilterList = styled.div``;
