import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { convertThumbnailToPictureProps } from '@tager/web-modules';
import { PictureProps } from '@tager/web-components';
import { isStringGuard } from '@tager/web-core';

import { Picture, PictureMediaQueryType } from '@/components/Picture';
import {
  hasProductCartInCookie,
  toggleProductCartInCookie,
} from '@/utils/common';
import {
  selectCountProductsInCart,
  setCountProductsInCookieThunk,
} from '@/store/reducers/cart';
import { useTypedSelector } from '@/store/store';
import { ReactComponent as AddToCartIcon } from '@/assets/svg/cart-dark.svg';
import { ReactComponent as InCartIcon } from '@/assets/svg/in-cart.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ProductModel } from '@/typings/model';

interface Props extends ProductModel {
  onClick?: () => void;
}

function CardProduct({
  onClick,
  image,
  name: title,
  id: productId,
  isBasePackage,
}: Props) {
  const iconProps: PictureProps<PictureMediaQueryType> = isStringGuard(image)
    ? { src: image }
    : convertThumbnailToPictureProps(image) ?? { src: '' };

  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);

  const productInCart = useTypedSelector((state) =>
    selectCountProductsInCart(state)
  );

  const product = useMemo(() => ({ id: productId }), [
    productId,
    productInCart,
  ]);

  useEffect(() => {
    const includesCurrentPost = hasProductCartInCookie(product);

    setActive(includesCurrentPost);
  }, [product]);

  function toggleAddInCart() {
    const newIsActive = toggleProductCartInCookie(product);
    setActive(newIsActive);
    dispatch(setCountProductsInCookieThunk());
  }

  return (
    <Component>
      <Image useSpinner={true} usePlaceholder={true} {...iconProps} />
      <Overlay />
      <Title>{title}</Title>
      <Link onClick={onClick} />
      {isBasePackage && <Label>Free</Label>}
      <Footer isHidePrice={true}>
        <AddCard inCart={isActive} onClick={toggleAddInCart}>
          {isActive ? <InCartIcon /> : <AddToCartIcon />}
        </AddCard>
      </Footer>
    </Component>
  );
}

export default CardProduct;

const Image = styled(Picture)`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;

  overflow: hidden !important;
  border-radius: 20px !important;

  transition: none !important;

  picture,
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  img {
    object-fit: cover;
  }

  &:before {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;

    pointer-events: none;

    height: 68px;

    background-image: linear-gradient(
      to top,
      rgba(11, 9, 102, 0),
      rgba(12, 14, 75, 0.45)
    );

    border-radius: 20px 20px 0 0;
    overflow: hidden;

    transition: 0.3s;
  }

  &:after {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    opacity: 0;
    pointer-events: none;

    height: 58px;

    background-image: linear-gradient(
      to bottom,
      rgba(11, 9, 102, 0) 14%,
      rgba(12, 14, 75, 0.7) 114%
    );

    border-radius: 0 0 20px 20px;
    overflow: hidden;

    transition: 0.3s;
  }

  img[data-image-status='failure'] {
    display: none;
  }
`;

const AddCard = styled.button<{ inCart: boolean }>`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: solid 2px ${({ inCart }) => (inCart ? colors.darkBlue : '#fff')};
  background-color: ${({ inCart }) => (inCart ? colors.darkBlue : '#fff')};
  text-align: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 4;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${media.tabletLarge(css`
    opacity: 1;
  `)};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: ${colors.darkBlue};
  z-index: 1;
  pointer-events: none;
  transition: 0.3s;
`;

const Component = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  padding-bottom: 155%;
  border-radius: 20px;

  background: #e2e7f1;

  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 18px 0 rgba(22, 22, 148, 0.23);

    ${Overlay} {
      opacity: 0.5;

      ${media.tabletLarge(css`
        opacity: 0;
      `)};
    }

    ${Image} {
      &[data-picture-status='success'] {
        &:after {
          opacity: 1;

          ${media.tabletLarge(css`
            opacity: 0;
          `)};
        }
      }
    }

    ${AddCard} {
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 2;

  width: 100%;
  height: 22%;

  padding: 15px 10px 0 10px;

  font-size: 16px;
  font-weight: 900;

  color: #fff;
`;

const Footer = styled.div<{ isHidePrice: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: ${({ isHidePrice }) =>
    isHidePrice ? 'flex-end' : 'space-between'};

  padding: 0 15px 15px;

  font-size: 14px;
  font-weight: 900;

  transition: 0.3s;
`;

const Label = styled.span`
  position: absolute;
  bottom: 25px;
  z-index: 2;

  padding: 4px 10.4px;
  border-top-right-radius: 20.5px;
  border-bottom-right-radius: 20.5px;

  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;

  color: #fff;
  background-image: linear-gradient(to left, #0018ff, #0c0342);
`;

const Link = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;
