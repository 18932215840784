import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import omit from 'lodash/omit';

import {
  createMediaQuery,
  ModalOverlay,
  ModalProps,
} from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import {
  getProductByCategoryAliasAndProductAliasThunk,
  selectProductByCategoryAliasAndProductAlias,
} from '@/store/reducers/product';
import { setCountProductsInCookieThunk } from '@/store/reducers/cart';
import {
  hasProductCartInCookie,
  toggleProductCartInCookie,
} from '@/utils/common';
import { breakpoints } from '@/constants/theme';

import DesktopModal from './components/DesktopModal';
import MobileModal from './components/MobileModal';
import MobileProductInfoPlaceholder from './components/MobileProductInfoPlaceholder';
import ProductInfoPlaceholder from './components/ProductInfoPlaceholder';

type Props = ModalProps<{
  categoryAlias: string;
  productAlias: string;
  pathname: string | undefined;
}>;

function ProductInfoModal({ closeModal, innerProps }: Props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [isActive, setActive] = useState(false);
  const [tag, setTag] = useState<string>('');
  const tagRef = useRef<string>('');
  const isMobile = useMedia(
    createMediaQuery({
      max: breakpoints.tabletLarge,
    })
  );

  const productResource = useTypedSelector((state) =>
    selectProductByCategoryAliasAndProductAlias(
      state,
      innerProps.categoryAlias,
      innerProps.productAlias
    )
  );

  const product = useMemo(() => {
    if (!productResource || !productResource.data) return { id: 0 };

    return { id: productResource.data.id };
  }, [productResource?.data?.id]);

  useEffect(() => {
    dispatch(
      getProductByCategoryAliasAndProductAliasThunk(
        innerProps.categoryAlias,
        innerProps.productAlias
      )
    );
  }, []);

  useEffect(() => {
    const includesCurrentPost = hasProductCartInCookie(product);

    setActive(includesCurrentPost);
  }, [product]);

  useEffect(() => {
    if (tag) {
      tagRef.current = tag;
      closeModal();
    }
  }, [tag]);

  useEffect(() => {
    function setUrl() {
      if (tagRef && tagRef.current) {
        router
          .push(
            {
              pathname: innerProps.categoryAlias,
              query: { query: tagRef.current },
            },
            undefined,
            { shallow: false }
          )
          .then(() => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          });
      } else {
        router.push(
          {
            pathname: innerProps.pathname,
            query: omit(router.query, ['slug', 'isq']),
          },
          undefined,
          { shallow: true }
        );
      }
    }
    return () => {
      setUrl();
    };
  }, []);

  function toggleAddToCart() {
    const newIsActive = toggleProductCartInCookie(product);
    setActive(newIsActive);
    dispatch(setCountProductsInCookieThunk());
  }

  if (!productResource || !productResource.data)
    return (
      <>
        {isMobile ? (
          <MobileProductInfoPlaceholder closeModal={closeModal} />
        ) : (
          <ProductInfoPlaceholder closeModal={closeModal} />
        )}
      </>
    );

  return (
    <>
      {isMobile ? (
        <MobileModal
          closeModal={closeModal}
          product={productResource.data}
          toggleAddToCart={toggleAddToCart}
          setTag={setTag}
          isActive={isActive}
        />
      ) : (
        <DesktopModal
          closeModal={closeModal}
          product={productResource.data}
          toggleAddToCart={toggleAddToCart}
          setTag={setTag}
          isActive={isActive}
        />
      )}
    </>
  );
}

export default ProductInfoModal;

export const Overlay = styled(ModalOverlay)`` as typeof ModalOverlay;
