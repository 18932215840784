import React from 'react';
import { useRouter } from 'next/router';

import { Page } from '@tager/web-components';
import { isNotNullish, isServer, useFixedVhProperty } from '@tager/web-core';
import { getSettingValueByKey } from '@tager/web-modules';

import Layout from '@/components/Layout';
import { useTypedSelector } from '@/store/store';
import {
  getHomeSeoParamsThunk,
  selectHomeSeoParams,
} from '@/store/reducers/tager/seo';
import {
  getCategoryAliasFromRouterQuerySlug,
  getParamAsNumber,
  getParamAsString2,
  getParamAsStringArray,
  getProductAliasFromRouterQuerySlug,
} from '@/utils/common';
import Catalog from '@/modules/Catalog';
import { CustomApp_PageContext } from '@/typings/hocs';
import {
  getCategoriesThunk,
  getCategoryByAliasThunk,
  getPlatformsThunk,
  getProductsThunk,
  setSelectedTechnologies,
  setSelectedPlatforms,
  setSelectedSubcategories,
  setSelectedFeatures,
  getProductByCategoryAliasAndProductAliasThunk,
  getProductByCategoryAliasAndProductAlias,
  setSelectedCategory,
} from '@/store/reducers/catalog';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { setCountProductsInCookieThunk } from '@/store/reducers/cart';
import { defaultCategory } from '@/modules/Catalog/components/Content/components/Categories';

interface Props {}

function CatalogPage(props: Props) {
  useFixedVhProperty({ shouldListenResize: true });

  const router = useRouter();
  const categoryAlias =
    getCategoryAliasFromRouterQuerySlug(router.query.slug) ?? '';
  const productAlias =
    getProductAliasFromRouterQuerySlug(router.query.slug) ?? '';

  const product = useTypedSelector((state) =>
    getProductByCategoryAliasAndProductAlias(state, {
      categoryAlias,
      productAlias,
    })
  );
  const seoParams = useTypedSelector(selectHomeSeoParams);

  const pageTitle = seoParams?.title;
  const pageDescription = seoParams?.description;
  const pageOpenGraphImage = seoParams?.openGraphImage;

  const title = product
    ? product.pageTitle || product.name || pageTitle
    : pageTitle;
  const description = product
    ? product.pageDescription || product.description || pageDescription
    : pageDescription;

  return (
    <Page
      title={title}
      description={description}
      openGraphImage={pageOpenGraphImage}
    >
      <Layout>
        <Catalog />
      </Layout>
    </Page>
  );
}

CatalogPage.getInitialProps = async ({
  store,
  query,
}: CustomApp_PageContext) => {
  const categoryAlias = getCategoryAliasFromRouterQuerySlug(query.slug);
  const productAlias = getProductAliasFromRouterQuerySlug(query.slug);

  const searchQuery = getParamAsString2(query, 'query');
  const videoFaceAr = getParamAsString2(query, 'videoFaceAr');
  const videoPostProcessing = getParamAsString2(query, 'videoPostProcessing');
  const platforms = getParamAsStringArray(query, 'platforms');
  const subcategories = getParamAsStringArray(query, 'subcategories');
  const features = getParamAsStringArray(query, 'features');
  const pageNumber = getParamAsNumber(query, 'page', 1);

  /** SelectedCategory **/
  store.dispatch(
    setSelectedCategory(categoryAlias ?? defaultCategory.urlAlias)
  );

  /** SelectedTechnologies **/
  if (videoFaceAr || videoPostProcessing) {
    store.dispatch(
      setSelectedTechnologies(
        [
          videoFaceAr ? { label: '', value: 'videoFaceAr' } : undefined,
          videoPostProcessing
            ? { label: '', value: 'videoPostProcessing' }
            : undefined,
        ].filter(isNotNullish)
      )
    );
  }

  /** SelectedPlatforms **/
  if (platforms.length > 0) {
    store.dispatch(
      setSelectedPlatforms({
        categoryAlias: categoryAlias ?? '/',
        data: platforms.map((value) => ({ label: '', value })),
      })
    );
  }

  /** SelectedSubcategories **/
  if (subcategories.length > 0 && categoryAlias) {
    store.dispatch(
      setSelectedSubcategories({
        key: categoryAlias,
        data: subcategories.map((value) => ({ label: '', value })),
      })
    );
  }

  /** SelectedFeatures **/
  if (features.length > 0 && categoryAlias) {
    store.dispatch(
      setSelectedFeatures({
        key: categoryAlias,
        data: features.map((value) => ({ label: '', value })),
      })
    );
  }

  /** Requests **/
  const requestsPromise = Promise.all([
    /** Platforms **/
    store.dispatch(getPlatformsThunk()),

    /** CategoryByAlias **/
    categoryAlias
      ? store.dispatch(getCategoryByAliasThunk(categoryAlias))
      : undefined,

    /** Categories **/
    store.dispatch(getCategoriesThunk()),

    /** SEO **/
    store.dispatch(getHomeSeoParamsThunk()),

    /** ProductByCategoryAliasAndProductAlias **/
    categoryAlias && productAlias
      ? store.dispatch(
          getProductByCategoryAliasAndProductAliasThunk({
            categoryAlias,
            productAlias,
          })
        )
      : undefined,

    /** Settings && Products **/
    store.dispatch(getSettingItemListThunk()).then((settingList) => {
      const pageSize =
        getSettingValueByKey(settingList, 'ITEMS_PER_PAGE') ?? undefined;
      return store.dispatch(
        getProductsThunk(
          {
            searchQuery,
            categoryAlias,
            videoFaceAr,
            videoPostProcessing,
            platforms,
            subcategories,
            features,
            pageSize,
            pageNumber,
          },
          { shouldInvalidate: true }
        )
      );
    }),

    /** Хрень какая-то 🤷‍ **/
    store.dispatch(setCountProductsInCookieThunk()),
  ]);

  if (isServer()) {
    await requestsPromise;
  }

  return {};
};

export default CatalogPage;
