import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { OptionType } from '@/typings/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  setSelectedFeatures,
  getSelectedFeatures,
  getFeatureList,
} from '@/store/reducers/catalog';
import {
  getCategoryAliasFromRouterQuerySlug,
  getCategoryUrlAliasFromRouterQuerySlug,
} from '@/utils/common';
import { Picture } from '@/components/Picture';

import Filter from '../Filter';
import Section from '../Section';

import { convertFeatureListToOptionList } from './Features.helpers';

interface Props {}

function Features(props: Props) {
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const categoryAlias = getCategoryAliasFromRouterQuerySlug(router.query.slug);
  const featureList = useTypedSelector((state) =>
    getFeatureList(
      state,
      getCategoryUrlAliasFromRouterQuerySlug(router.query.slug, 'all')
    )
  );
  const optionList = convertFeatureListToOptionList(featureList);
  const selectedFeatures = useTypedSelector((state) =>
    getSelectedFeatures(state, categoryAlias ?? '')
  );

  function isSelected(features: OptionType): boolean {
    return selectedFeatures.some(
      (selected) => selected.value === features.value
    );
  }

  function handleFilterChange(features: OptionType) {
    const isChecked: boolean = isSelected(features);

    const newSelectedFeatures: Array<OptionType> = isChecked
      ? selectedFeatures.filter((selected) => selected.value !== features.value)
      : [...selectedFeatures, features];

    if (categoryAlias) {
      dispatch(
        setSelectedFeatures({ key: categoryAlias, data: newSelectedFeatures })
      );
    }
  }

  if (featureList.length <= 0) {
    return null;
  }

  return (
    <Section label="Features">
      <FilterList>
        {optionList.map((feature, index) => (
          <Filter
            icon={
              featureList[index] && featureList[index].icon ? (
                <Picture src={featureList[index].icon} />
              ) : undefined
            }
            key={index}
            label={feature.label}
            checked={isSelected(feature)}
            onChange={() => handleFilterChange(feature)}
          />
        ))}
      </FilterList>
    </Section>
  );
}

export default React.memo(Features);

const FilterList = styled.div``;
