import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { getParamAsString2 } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { getCategoryList, getProductList } from '@/store/reducers/catalog';

import {
  convertFromProductListToProductsSections,
  getCategoryNameByCategoryAlias,
} from './SearchResultForAllProducts.helpers';
import Section from './components/Section';

interface Props {}

function SearchResultForAllProducts(props: Props) {
  const router = useRouter();
  const searchQuery = getParamAsString2(router.query, 'query');

  const productList = useTypedSelector(getProductList);
  const categoryList = useTypedSelector(getCategoryList);

  const productsSections = convertFromProductListToProductsSections(
    productList
  );

  return (
    <Component>
      <ResultsFor>Results for “{searchQuery}”</ResultsFor>
      {Object.entries(productsSections).map(([alias, products], index) => (
        <Section
          key={index}
          title={getCategoryNameByCategoryAlias(alias, categoryList) ?? alias}
          seeMoreLink={`/${alias}`}
          products={products}
        />
      ))}
    </Component>
  );
}

export default SearchResultForAllProducts;

const Component = styled.div`
  margin-top: 20px;
`;

const ResultsFor = styled.div`
  margin-bottom: 15px;

  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #0d0735;
`;
