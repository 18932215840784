import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { OptionType } from '@/typings/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  setSelectedSubcategories,
  getSelectedSubcategories,
  getSubcategoryList,
} from '@/store/reducers/catalog';
import { getCategoryAliasFromRouterQuerySlug } from '@/utils/common';
import { Picture } from '@/components/Picture';

import Filter from '../Filter';
import Section from '../Section';

import { convertSubcategoryListToOptionList } from './Subcategories.helpers';

interface Props {}

function Subcategories(props: Props) {
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const categoryAlias = getCategoryAliasFromRouterQuerySlug(router.query.slug);
  const subcategoryList = useTypedSelector((state) =>
    getSubcategoryList(state, categoryAlias ?? '')
  );
  const optionList = convertSubcategoryListToOptionList(subcategoryList);
  const selectedSubcategories = useTypedSelector((state) =>
    getSelectedSubcategories(state, categoryAlias ?? '')
  );

  function isSelected(subcategories: OptionType): boolean {
    return selectedSubcategories.some(
      (selected) => selected.value === subcategories.value
    );
  }

  function handleFilterChange(subcategories: OptionType) {
    const isChecked: boolean = isSelected(subcategories);

    const newSelectedSubcategories: Array<OptionType> = isChecked
      ? selectedSubcategories.filter(
          (selected) => selected.value !== subcategories.value
        )
      : [...selectedSubcategories, subcategories];

    if (categoryAlias) {
      dispatch(
        setSelectedSubcategories({
          key: categoryAlias,
          data: newSelectedSubcategories,
        })
      );
    }
  }

  if (subcategoryList.length <= 0) {
    return null;
  }

  return (
    <Section label="Subcategories">
      <FilterList>
        {optionList.map((subcategory, index) => (
          <Filter
            icon={
              subcategoryList[index] && subcategoryList[index].icon ? (
                <Picture src={subcategoryList[index].icon} />
              ) : undefined
            }
            key={index}
            label={subcategory.label}
            checked={isSelected(subcategory)}
            onChange={() => handleFilterChange(subcategory)}
          />
        ))}
      </FilterList>
    </Section>
  );
}

export default React.memo(Subcategories);

const FilterList = styled.div``;
