import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { PictureProps } from '@tager/web-components';
import { isStringGuard, Nullable } from '@tager/web-core';

import { Picture, PictureMediaQueryType } from '@/components/Picture';
import ButtonIcon from '@/components/ButtonIcon';

type CardProductType = {
  videoUrl: string;
  previewUrl: Nullable<ThumbnailType> | string;
};

function Video({ videoUrl, previewUrl }: CardProductType) {
  const videoEl = useRef<HTMLVideoElement>(null);
  const [isPreview, setPreview] = useState(true);
  const [isPlay, setPlay] = useState(false);

  const previewProps: PictureProps<PictureMediaQueryType> = isStringGuard(
    previewUrl
  )
    ? { src: previewUrl }
    : convertThumbnailToPictureProps(previewUrl) ?? { src: '' };

  function togglePlay() {
    if (!videoEl.current) return null;
    if (videoEl.current.paused) {
      setPreview(false);
      setPlay(true);
      videoEl.current.play();
    } else {
      videoEl.current.pause();
      setPlay(false);
    }
  }

  return (
    <ContentBlock hasVideo={!!videoUrl} onClick={togglePlay}>
      <ImageBlock isPreview={isPreview} {...previewProps} />
      <VideoBlock isPreview={isPreview}>
        <VideoContent ref={videoEl} loop muted>
          <source src={videoUrl} type="video/mp4" />
        </VideoContent>
      </VideoBlock>
      {videoUrl ? (
        <Icon icon={isPlay ? 'pause' : 'play'} isCircle={true} />
      ) : null}
    </ContentBlock>
  );
}

const VideoContent = styled.video``;

const Icon = styled(ButtonIcon)`
  position: absolute;
  left: 30px;
  bottom: 30px;
`;

const ImageBlock = styled(Picture)<{ isPreview: boolean }>`
  position: static;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    //display: ${(props) => (props.isPreview ? 'block' : 'none')};
  }
`;

const VideoBlock = styled.div<{ isPreview: boolean }>`
  video {
    display: ${(props) => (props.isPreview ? 'none' : 'block')};
    position: absolute;
    height: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ContentBlock = styled.div<{ hasVideo: boolean }>`
  min-width: 200px;
  position: relative;
  cursor: ${(props) => (props.hasVideo ? 'pointer' : 'default')};
  transition: 0.3s;
  overflow: hidden;
  padding-top: 155%;
  &:hover {
    ${Icon} {
      opacity: 0.7;
    }
  }
`;
export default Video;
