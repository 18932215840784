import React from 'react';

import { PlatformModel } from '@/typings/model';
import { OptionType } from '@/typings/common';
import { ReactComponent as AppleIcon } from '@/assets/svg/platforms/apple.svg';
import { ReactComponent as AndroidIcon } from '@/assets/svg/platforms/android.svg';
import { ReactComponent as WindowsIcon } from '@/assets/svg/platforms/windows.svg';
import { ReactComponent as WebIcon } from '@/assets/svg/platforms/web.svg';
import { ReactComponent as UnityIcon } from '@/assets/svg/platforms/unity.svg';

export function convertPlatformListToOptionList(
  platformList: Array<PlatformModel>
): Array<OptionType> {
  return platformList.map((platform) => ({
    value: platform.id ?? '',
    label: platform.name ?? '',
  }));
}

export function applyIconToPlatform(type: string): React.ReactNode | null {
  switch (type) {
    case 'IOS':
    case 'MACOS':
      return <AppleIcon />;
    case 'ANDROID':
      return <AndroidIcon />;
    case 'WINDOWS':
      return <WindowsIcon />;
    case 'WEB':
      return <WebIcon />;
    case 'UNITY':
      return <UnityIcon />;
    default:
      return null;
  }
}
