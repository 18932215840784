import React from 'react';
import styled, { css } from 'styled-components';

import Checkbox from './components/Checkbox';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  label: string;
}

function Filter({ icon, label, disabled, ...other }: Props) {
  return (
    <Component disabled={disabled}>
      {icon && <Icon>{icon}</Icon>}
      <Label isIcon={Boolean(icon)}>{label}</Label>
      <Checkbox disabled={disabled} {...other} />
    </Component>
  );
}

export default Filter;

const Component = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
      user-select: none;
    `};

  transition: opacity 0.3s;
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

const Label = styled.span<{ isIcon: boolean }>`
  flex: 1 0 0%;

  cursor: pointer;

  width: 100%;
  max-width: 100%;

  margin-right: 0;

  ${({ isIcon }) =>
    isIcon &&
    css`
      margin: 0 10px;
    `};

  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #0d0735;
`;
