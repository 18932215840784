import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

import { useMedia } from '@tager/web-core';

import { ReactComponent as SettingsIcon } from '@/assets/svg/settings.svg';
import Hint from '@/modules/Catalog/components/Hint';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import Aside from './components/Aside';
import Content from './components/Content';

function Catalog() {
  const isMaxWidth992px = useMedia('(max-width: 992px)');

  /** Hint **/

  const [isOkHint, setIsOkHint] = useLocalStorage('hint-drawer', false);

  function handleHintClose() {
    setIsOkHint(true);
  }

  /** Drawer **/

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  function handleShowDrawerClick() {
    setIsOpenDrawer(!isOpenDrawer);
  }

  function handleDrawerClose() {
    setIsOpenDrawer(false);
  }

  return (
    <Component>
      <ComponentContainer>
        {isMaxWidth992px && !isOkHint && <Hint onClose={handleHintClose} />}

        <ShowDrawerButton onClick={handleShowDrawerClick} isOpenHint={isOkHint}>
          <SettingsIcon />
        </ShowDrawerButton>

        {isMaxWidth992px ? (
          <StyledDrawer
            open={isOpenDrawer}
            width={'100vw'}
            handler={false}
            onClose={handleDrawerClose}
            level={null}
          >
            <Aside onDrawerClose={handleDrawerClose} />
          </StyledDrawer>
        ) : (
          <Aside />
        )}
        <Content />
      </ComponentContainer>
    </Component>
  );
}

export default Catalog;

const Component = styled.div`
  margin-top: var(--header-height, 70px);

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - var(--header-height, 70px));

    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const ComponentContainer = styled.div`
  padding: 30px 15px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 264px 1fr;
    column-gap: 20px;
    padding: 20px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1280px) {
    grid-template-columns: 21.286% 1fr;
  }

  @media (min-width: 1400px) {
  }
`;

const ShowDrawerButton = styled.button<{ isOpenHint: boolean }>`
  position: fixed;
  top: calc(100vh / 2);
  left: 0;
  transform: translateY(-50%);
  z-index: 99;
  cursor: pointer;

  display: none;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 92px;

  background: #e2e7f1;
  border-radius: 0 8px 8px 0;

  ${({ isOpenHint }) =>
    !isOpenHint &&
    css`
      z-index: 999;
    `};

  @media (max-width: 992px) {
    display: flex;
  }
`;

const StyledDrawer = styled(Drawer)`
  z-index: 999;

  .drawer-content {
    overflow: initial;

    > * {
      display: block;
    }
  }
`;
