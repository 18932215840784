import React from 'react';
import styled from 'styled-components';

import { OptionType } from '@/typings/common';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  setSelectedTechnologies,
  getSelectedTechnologies,
} from '@/store/reducers/catalog';
import { ReactComponent as FaceARIcon } from '@/assets/svg/technologies/faceAR.svg';
import { ReactComponent as PostProcessingIcon } from '@/assets/svg/technologies/postprocessing.svg';

import Filter from '../Filter';
import Section from '../Section';

interface TechnologiesModel extends OptionType {
  icon: React.ReactNode;
}

const technologiesList: Array<TechnologiesModel> = [
  { value: 'videoFaceAr', label: 'Realtime', icon: <FaceARIcon /> },
  {
    value: 'videoPostProcessing',
    label: 'Post Processing',
    icon: <PostProcessingIcon />,
  },
];

interface Props {}

function Technologies(props: Props) {
  const dispatch = useTypedDispatch();

  const selectedTechnologies = useTypedSelector(getSelectedTechnologies);

  function isSelected(technologies: TechnologiesModel): boolean {
    return selectedTechnologies.some(
      (selected) => selected.value === technologies.value
    );
  }

  function handleFilterChange(technologies: TechnologiesModel) {
    const isChecked: boolean = isSelected(technologies);

    const newSelectedTechnologies: Array<OptionType> = isChecked
      ? selectedTechnologies.filter(
          (selected) => selected.value !== technologies.value
        )
      : [
          ...selectedTechnologies,
          { value: technologies.value, label: technologies.value },
        ];

    dispatch(setSelectedTechnologies(newSelectedTechnologies));
  }

  return (
    <Section label="Application">
      <FilterList>
        {technologiesList.map((technologies, index) => (
          <Filter
            icon={technologies.icon}
            key={index}
            label={technologies.label}
            checked={isSelected(technologies)}
            onChange={() => handleFilterChange(technologies)}
          />
        ))}
      </FilterList>
    </Section>
  );
}

export default React.memo(Technologies);

const FilterList = styled.div``;
