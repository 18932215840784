import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import {
  getCategoryAliasFromRouterQuerySlug,
  getParamAsString2,
  getProductAliasFromRouterQuerySlug,
} from '@/utils/common';
import { getProductList, getProductsStatus } from '@/store/reducers/catalog';
import { useTypedSelector } from '@/store/store';

import SearchResultForAllProducts from './components/SearchResultForAllProducts';
import Pagination from './components/Pagination';
import Products from './components/Products';
import Categories from './components/Categories';

interface Props {}

function Content(props: Props) {
  const router = useRouter();

  const productStatus = useTypedSelector(getProductsStatus);
  const productList = useTypedSelector(getProductList);
  const searchQuery = getParamAsString2(router.query, 'query');
  const categoryAlias = getCategoryAliasFromRouterQuerySlug(router.query.slug);

  const isSearchAllProducts =
    router.query.isq ||
    Boolean(
      !categoryAlias &&
        searchQuery &&
        productStatus === 'succeeded' &&
        productList.length > 0
    );

  return (
    <Component>
      <ComponentContainer>
        <Categories />
        {isSearchAllProducts ? (
          <SearchResultForAllProducts />
        ) : (
          <>
            <Products />
            <Pagination />
          </>
        )}
      </ComponentContainer>
    </Component>
  );
}

export default Content;

const Component = styled.section``;

const ComponentContainer = styled.div`
  margin-bottom: 40px;
`;
