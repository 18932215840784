import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactPaginate from 'react-paginate';
import { useRouter } from 'next/router';

import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { getMeta, getProductList } from '@/store/reducers/catalog';
import { getParamAsNumber } from '@/utils/common';

import Previous from './components/Previous';
import Next from './components/Next';

interface Props {}

function Pagination(props: Props) {
  const router = useRouter();

  const meta = useTypedSelector(getMeta);
  const productList = useTypedSelector(getProductList);

  const [pageNumber, setPageNumber] = useState<number>(
    getParamAsNumber(router.query, 'page', 1)
  );

  useEffect(() => {
    setPageNumber(getParamAsNumber(router.query, 'page', 1));
  }, [router.query]);

  function handlePageClick({ selected }: { selected: number }) {
    const pageNumber = selected + 1;

    setPageNumber(pageNumber);

    const query = { ...router.query, page: pageNumber };

    router.replace({ query }, undefined, { shallow: false });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  const isMaxWidth768px = useMedia('(max-width: 768px)');

  const [isOpenPagination, setIsOpenPagination] = useState(false);

  function handleToggleClick() {
    setIsOpenPagination(!isOpenPagination);
  }

  if (productList.length <= 0 || !meta || meta?.page.count <= 1) {
    return null;
  }

  if (isMaxWidth768px) {
    return (
      <MobileComponent>
        <MobileComponentContainer isOpenPagination={isOpenPagination}>
          <ReactPaginate
            forcePage={pageNumber - 1}
            previousLabel={null}
            nextLabel={null}
            pageCount={meta?.page.count ?? 0}
            onPageChange={handlePageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={meta?.page.count ?? 5}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </MobileComponentContainer>
        <Toggle onClick={handleToggleClick}>
          {pageNumber} / {meta?.page.count ?? 0}
        </Toggle>
      </MobileComponent>
    );
  }

  return (
    <Component>
      <ReactPaginate
        forcePage={pageNumber - 1}
        previousLabel={<Previous />}
        nextLabel={<Next />}
        pageCount={meta?.page.count ?? 0}
        onPageChange={handlePageClick}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </Component>
  );
}

export default Pagination;

const MobileComponent = styled.div`
  position: fixed;
  left: 0;
  bottom: 5.8vh;
  z-index: 99;

  display: flex;
  align-items: center;

  height: 40px;
  border-radius: 0 100px 100px 0;
`;

const MobileComponentContainer = styled.div<{ isOpenPagination: boolean }>`
  overflow-x: auto;
  overflow-y: hidden;

  /** Hide Scrollbars But Keep Functionality **/

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  width: 0;

  background-color: #fff;

  transition: width 0.3s ease-in-out;

  ${({ isOpenPagination }) =>
    isOpenPagination &&
    css`
      width: 100%;
      max-width: 220px;
    `};

  .pagination {
    display: flex;
    align-items: center;

    width: 100%;
    height: 40px;

    padding: 5px 10px;

    background-color: #fff;

    .previous,
    .next {
      display: none;
    }

    > li:not(.previous, .next) a {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30px;
      height: 30px;
      border-radius: 100%;

      font-weight: 900;
      font-size: 14px;
      line-height: 17px;

      color: #0d0735;
      background: #fff;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    > li.active a {
      color: #fff;
      background: #0d0735;
    }
  }
`;

const Toggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 0 19px;

  background: linear-gradient(270deg, #0010ad 0%, #030019 100%);
  border-radius: 0 100px 100px 0;

  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;

  white-space: nowrap;
`;

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 60px;

  .pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    list-style: none;
    outline: none;

    margin: 0 auto;

    > li {
      > a {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0 2px;

        border-radius: 50%;

        outline: none;
        cursor: pointer;
      }

      &:not(.previous, .next) {
        > a {
          width: 40px;
          height: 40px;

          font-size: 16px;
          font-weight: 900;

          color: #000;
        }
      }

      &:not(.previous, .next, .active) > a:hover {
        opacity: 0.8;
      }

      &.previous > a {
        margin-right: 17px;
      }

      &.next > a {
        margin-left: 17px;
      }

      &.active > a {
        font-weight: 900;

        background-color: #0d0735;
        color: #fff;
      }

      &.disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
    }
`;
