import React from 'react';
import styled from 'styled-components';

import ButtonIcon from '@/components/ButtonIcon';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';
import { colors } from '@/constants/theme';

interface Props {
  closeModal: () => void;
}

function ProductInfoPlaceholder({ closeModal }: Props) {
  return (
    <Inner>
      <Icon icon={'close'} onClick={closeModal} />
      <HeaderBlock>
        <HeaderContent>
          <Title />
          <CategoriesValueBlock />
        </HeaderContent>
      </HeaderBlock>
      <ContentBlock>
        <LeftBlock />
        <RightBlock>
          <TitlePrice />
          <Description />
          <Category>
            <SubTitle />
            <Technology />
          </Category>
          <Category>
            <SubTitle />
            <Technology />
          </Category>
          <AddToCard />
        </RightBlock>
      </ContentBlock>
    </Inner>
  );
}

export default ProductInfoPlaceholder;

const HeaderBlock = styled.div`
  border-bottom: solid 1px #eceef3;
  padding: 30px 0 16px;
`;

const HeaderContent = styled.div`
  padding: 0 40px;
`;

const Title = styled.div`
  ${customPlaceholder};
  margin-bottom: 9px;
  width: 170px;
  height: 30px;
`;

const TitlePrice = styled.div`
  ${customPlaceholder};
  margin-bottom: 9px;
  width: 70px;
  height: 30px;
`;

const Icon = styled(ButtonIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Inner = styled.div<{ width?: number }>`
  position: relative;
  background-color: #fff;
  z-index: 11;
  height: 600px;
  width: 740px;
  border-radius: 20px;
  overflow: hidden;
`;

const ContentBlock = styled.div`
  display: flex;
  height: calc(100% - 111px);
`;

const LeftBlock = styled.div`
  ${customPlaceholder};
  width: 320px;
`;

const RightBlock = styled.div`
  padding: 20px 40px 30px;
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.darkBlue};
  max-width: 320px;
  margin: 10px 0 20px;
`;

const Category = styled.div`
  margin-bottom: 15px;
`;

const SubTitle = styled.div`
  width: 80px;
  height: 20px;
  ${customPlaceholder};
  margin-bottom: 5px;
`;

const CategoriesValueBlock = styled.div`
  ${customPlaceholder};
  width: 62px;
  height: 20px;
  margin-bottom: 5px;
`;

const Technology = styled.div`
  width: 232px;
  height: 24px;
  ${customPlaceholder};
`;

const AddToCard = styled.div`
  width: 100%;
  margin: auto 0px 0px;
  border-radius: 20px;
  height: 40px;
  ${customPlaceholder};
`;
