import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getSelectedTechnologies,
  getSelectedPlatforms,
  getSelectedSubcategories,
  getSelectedFeatures,
  setSelectedTechnologies,
  setSelectedPlatforms,
  setSelectedSubcategories,
  setSelectedFeatures,
} from '@/store/reducers/catalog';
import { getCategoryAliasFromRouterQuerySlug } from '@/utils/common';

interface Props {
  onDrawerClose?: () => void;
}

function AsideFooter({ onDrawerClose }: Props) {
  const router = useRouter();
  const dispatch = useTypedDispatch();

  const categoryAlias = getCategoryAliasFromRouterQuerySlug(router.query.slug);

  const selectedTechnologies = useTypedSelector(getSelectedTechnologies);
  const selectedPlatforms = useTypedSelector((state) =>
    getSelectedPlatforms(state, categoryAlias ?? '/')
  );

  const selectedSubcategories = useTypedSelector((state) =>
    getSelectedSubcategories(state, categoryAlias ?? '')
  );
  const selectedFeatures = useTypedSelector((state) =>
    getSelectedFeatures(state, categoryAlias ?? '')
  );

  const isVideoFaceAr = selectedTechnologies.some(
    (technology) => technology.value === 'videoFaceAr'
  );
  const videoFaceAr = isVideoFaceAr ? '1' : undefined;

  const isVideoPostProcessing = selectedTechnologies.some(
    (technology) => technology.value === 'videoPostProcessing'
  );
  const videoPostProcessing = isVideoPostProcessing ? '1' : undefined;

  const isSomethingSelected =
    selectedTechnologies.length > 0 ||
    selectedPlatforms.length > 0 ||
    selectedSubcategories.length > 0 ||
    selectedFeatures.length > 0;

  function handleResetButtonClick() {
    dispatch(setSelectedTechnologies([]));
    dispatch(
      setSelectedPlatforms({ categoryAlias: categoryAlias ?? '/', data: [] })
    );
    if (categoryAlias) {
      dispatch(setSelectedSubcategories({ key: categoryAlias, data: [] }));
      dispatch(setSelectedFeatures({ key: categoryAlias, data: [] }));
    }
  }

  function handleApplyButtonClick() {
    if (onDrawerClose) {
      onDrawerClose();
    }

    const platforms = selectedPlatforms.map(
      (selectedPlatform) => selectedPlatform.value
    );
    const subcategories = selectedSubcategories.map(
      (selectedSubcategory) => selectedSubcategory.value
    );
    const features = selectedFeatures.map(
      (selectedFeature) => selectedFeature.value
    );

    const query: Record<string, Array<string> | string> = {
      ...omit(router.query, ['page']),
      platforms,
      subcategories,
      features,
    };

    if (videoFaceAr) {
      query.videoFaceAr = videoFaceAr;
    } else {
      delete query.videoFaceAr;
    }

    if (videoPostProcessing) {
      query.videoPostProcessing = videoPostProcessing;
    } else {
      delete query.videoPostProcessing;
    }

    router.replace({ query }, undefined, { shallow: false });
  }

  return (
    <Component>
      {isSomethingSelected ? (
        <ResetButton type="button" onClick={handleResetButtonClick}>
          Reset
        </ResetButton>
      ) : (
        <SpaceBetween />
      )}

      <ApplyButton type="button" onClick={handleApplyButtonClick}>
        Apply
      </ApplyButton>
    </Component>
  );
}

export default React.memo(AsideFooter);

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 40px 30px 40px 30px;
  border-top: 1px solid #e2e7f1;

  background-color: #fff;

  @media (max-width: 992px) {
    padding: 40px 30px 40px 30px;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const ResetButton = styled.button`
  padding: 6px 16px;
  border-radius: 20px;

  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #0d0735;
  background: #e2e7f1;

  transition: opacity 0.15s ease-in-out;

  &:hover {
    color: rgb(13 7 53 / 70%);
    background-color: rgba(226, 231, 241, 0.7);
  }

  &:active {
    background-color: rgba(226, 231, 241, 0.9);
  }

  &:disabled {
    color: rgb(13 7 53 / 40%);
    background-color: #e2e7f1;
  }
`;

const SpaceBetween = styled.div`
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
`;

const ApplyButton = styled.button`
  padding: 6px 16px;
  border-radius: 20px;

  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  background: #0d0735;

  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
  }
`;
