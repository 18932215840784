import React from 'react';
import styled from 'styled-components';

import Skeleton from '@/components/Skeleton';

function SkeletonCardProduct() {
  return <Component />;
}

export default SkeletonCardProduct;

const Component = styled(Skeleton)`
  overflow: hidden;
  padding-bottom: 155%;
  border-radius: 20px;

  background-color: #e2e7f1;
`;
