import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { scroller } from '@tager/web-core';

interface Props {
  onClose?: () => void;
}

function Hint({ onClose }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    scroller.lock();
  }, []);

  function handleClose() {
    setIsOpen(!isOpen);
    scroller.unlock();
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 400);
  }

  return (
    <>
      <Overlay isOpen={isOpen} />
      <Component isOpen={isOpen}>
        <ComponentContainer>
          <Text>
            Use filter to more comfortable
            <br /> search
          </Text>
          <Close type="button" onClick={handleClose}>
            ok
          </Close>
        </ComponentContainer>
      </Component>
    </>
  );
}

export default Hint;

const Component = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: calc(100vh / 2);
  left: 0;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;

  background: #000;
  opacity: 0.85;
  border-radius: 8px;

  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateY(-50%) translateX(-100%);
    `};
`;

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 18px 12px 18px 72px;
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  opacity: 0;
  visibility: hidden;

  background: rgb(13 7 53 / 20%);

  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #fff;
`;

const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  border-radius: 6px;

  width: 56px;
  height: 30px;

  margin-left: 15px;

  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #0d0735;
`;
