import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { generateNumberArray } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import ProductCard, { SkeletonCardProduct } from '@/components/CardProduct';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getProductByCategoryAliasAndProductAliasThunk,
  getProducts,
} from '@/store/reducers/catalog';
import ProductInfoModal from '@/components/modals/ProductInfoModal';
import {
  getCategoryAliasFromRouterQuerySlug,
  getProductAliasFromRouterQuerySlug,
} from '@/utils/common';
import useSettingItem from '@/hooks/useSettingItem';

import NothingFound from './components/NothingFound';

interface Props {}

function Products(props: Props) {
  const openModal = useModal();
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const pageSize = useSettingItem('ITEMS_PER_PAGE') ?? undefined;
  const { status, data: productList } = useTypedSelector(getProducts);

  useEffect(() => {
    const categoryAlias = getCategoryAliasFromRouterQuerySlug(
      router.query.slug
    );
    const productAlias = getProductAliasFromRouterQuerySlug(router.query.slug);

    if (categoryAlias && productAlias) {
      openModal(ProductInfoModal, {
        categoryAlias,
        productAlias,
        pathname: categoryAlias,
      });
    }
  }, [openModal, router.query.slug]);

  function handleProductClick(index: number) {
    const categoryAlias = productList[index].categoryAlias;
    const productAlias = productList[index].urlAlias;

    dispatch(
      getProductByCategoryAliasAndProductAliasThunk({
        categoryAlias,
        productAlias,
      })
    );

    router.push(
      {
        pathname: `/${categoryAlias}/${productAlias}`,
        query: omit(router.query, ['slug']),
      },
      undefined,
      {
        shallow: true,
      }
    );

    openModal(ProductInfoModal, {
      categoryAlias: categoryAlias,
      productAlias: productAlias,
      pathname: getCategoryAliasFromRouterQuerySlug(router.query.slug),
    });
  }

  return (
    <Component>
      {status === 'pending' ? (
        <ProductList>
          {generateNumberArray(
            productList.length > 0 ? productList.length : pageSize ?? 20
          ).map((number) => (
            <SkeletonCardProduct key={number} />
          ))}
        </ProductList>
      ) : status === 'succeeded' && productList.length > 0 ? (
        <ProductList>
          {productList.map((product, index) => (
            <ProductCard
              {...product}
              key={product.id}
              onClick={() => handleProductClick(index)}
            />
          ))}
        </ProductList>
      ) : (
        <NothingFound />
      )}
    </Component>
  );
}

export default Products;

const Component = styled.div`
  margin-top: 30px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin-top: 12px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1400px) {
    gap: 32px;
  }
`;
