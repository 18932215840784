import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  className?: string;
}

function Skeleton(props: Props) {
  return <Component {...props} />;
}

export default Skeleton;

const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Component = styled.span`
  position: relative;
  overflow: hidden;
  display: block;
  background-color: rgba(0, 0, 0, 0.11);
  height: 1.2em;

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: ${wave} 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.04),
      transparent
    );
  }
`;
