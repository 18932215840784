import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { scroller } from '@tager/web-core';

import Video from '@/components/Video';
import ButtonIcon from '@/components/ButtonIcon';
import Button from '@/components/Button';
import CategoriesValue from '@/components/CategoriesValue';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';
import NoPicture2x from '@/assets/images/no-picture@2x.png';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ProductFull } from '@/typings/model';

interface Props {
  closeModal: () => void;
  product: ProductFull;
  setTag: (tag: string) => void;
  toggleAddToCart: () => void;
  isActive: boolean;
}

function MobileModal({
  closeModal,
  product,
  setTag,
  toggleAddToCart,
  isActive,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scroller.lock(ref.current);
  }, []);

  return (
    <Wrapper ref={ref}>
      <Inner>
        <Icon icon={'close'} onClick={closeModal} />
        <>
          <HeaderBlock>
            <HeaderContent>
              {product.isBasePackage && <Label>Free</Label>}
              <Video
                videoUrl={product.video}
                previewUrl={product.image ? product.image : NoPicture2x}
              />
            </HeaderContent>
            <AddToCard
              onClick={toggleAddToCart}
              variant={'contained'}
              fullWidth={true}
            >
              {isActive ? 'Remove from Cart' : 'Add to Cart'}
            </AddToCard>
            <TitleBlock>
              <Title>{product.name}</Title>
              <CategoriesValue
                name={product.subcategories
                  .map((subcategory) => subcategory?.name)
                  .join(', ')}
                icon={product.category.icon}
              />
            </TitleBlock>
          </HeaderBlock>

          <ContentBlock>
            {product.description ? (
              <Description>{product.description}</Description>
            ) : null}
            {product.tags ? (
              <TagList>
                {product.tags.map((tag, index) => (
                  <>
                    <Tag
                      onClick={() => {
                        setTag(tag);
                      }}
                      key={index}
                    >
                      #{tag}
                    </Tag>{' '}
                  </>
                ))}
              </TagList>
            ) : null}
            {product.features.length ? (
              <Category>
                <SubTitle>Features</SubTitle>
                <Technology>
                  {product.features.map((feature) => feature?.name).join(', ')}
                </Technology>
              </Category>
            ) : null}
            {product.platforms.length ? (
              <Category>
                <SubTitle>Platforms</SubTitle>
                <Technology>{product.platforms.join(', ')}</Technology>
              </Category>
            ) : null}
          </ContentBlock>
        </>
      </Inner>
    </Wrapper>
  );
}

export default MobileModal;

const HeaderBlock = styled.div`
  border-bottom: solid 1px #eceef3;
  padding: 15px 15px 20px;
`;

const TitleBlock = styled.div`
  margin-top: 30px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  line-height: 1.25;
  color: ${colors.darkBlue};
  margin: 0 0 9px;
`;

const Icon = styled(ButtonIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Inner = styled.div`
  position: relative;
  z-index: 11;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
`;

const Wrapper = styled.div`
  background-color: #fff;
  overflow: auto;
`;

const ContentBlock = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
`;

const HeaderContent = styled.div`
  position: relative;
  ${customPlaceholder};
  width: 294px;
  margin: 0 auto 30px;
  border-radius: 20px;
  overflow: hidden;
  ${media.mobileMedium(css`
    width: 255px;
  `)}
  ${media.mobileSmall(css`
    width: 200px;
  `)}
`;

const Label = styled.span`
  top: 20px;
  position: absolute;
  border-top-right-radius: 20.5px;
  border-bottom-right-radius: 20.5px;
  background-image: linear-gradient(to left, #0018ff, #0c0342);
  padding: 4px 10.4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #ffffff;
  z-index: 1;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.darkBlue};
  margin: 10px 0 20px;
  max-width: none;
  word-break: break-word;
`;

const TagList = styled.ul`
  line-height: 1.43;
  margin-bottom: 20px;
`;
const Tag = styled.li`
  color: #0017fe;
  display: inline-block;
`;

const Category = styled.div`
  margin-bottom: 15px;
`;

const SubTitle = styled.div`
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.46px;
  color: #9e9ca7;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Technology = styled.div`
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: ${colors.darkBlue};
`;

const AddToCard = styled(Button)`
  margin: auto 0 0;
`;
