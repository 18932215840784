import { CategoryModel, ProductModel } from '@/typings/model';

export function convertFromProductListToProductsSections(
  productList: Array<ProductModel>
) {
  return productList.reduce<Record<string, Array<ProductModel>>>(
    (acc, product) => {
      if (acc[product.categoryAlias]) {
        acc[product.categoryAlias] = [product, ...acc[product.categoryAlias]];
      } else {
        acc[product.categoryAlias] = [product];
      }
      return acc;
    },
    {}
  );
}

export function getCategoryNameByCategoryAlias(
  categoryAlias: string,
  categoryList: Array<CategoryModel>
): string | undefined {
  const foundCategory = categoryList.find(
    (category) => category.urlAlias === categoryAlias
  );
  return foundCategory?.name ?? undefined;
}
