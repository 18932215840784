import React from 'react';
import styled from 'styled-components';

interface Props {}

function NothingFound(props: Props) {
  return (
    <Component>Sorry, there are no assets in this category yet.</Component>
  );
}

export default NothingFound;

const Component = styled.p`
  padding-top: 14vh;
  margin: 0 auto 0 auto;

  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #0d0735;

  @media (max-width: 768px) {
    max-width: 280px;
  }
`;
