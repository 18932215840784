import { FeatureModel } from '@/typings/model';
import { OptionType } from '@/typings/common';

export function convertFeatureListToOptionList(
  featureList: Array<FeatureModel>
): Array<OptionType> {
  return featureList.map((feature) => ({
    value: String(feature.id),
    label: feature.name ?? '',
  }));
}
