import React from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  children: React.ReactNode;
}

function Section({ label, children }: Props) {
  return (
    <Component>
      <Label>{label}</Label>
      {children}
    </Component>
  );
}

export default Section;

const Component = styled.section`
  margin-bottom: 30px;
  border-top: 1px solid #eceef3;
`;

const Label = styled.div`
  margin-top: 5px;
  margin-bottom: 25px;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  letter-spacing: 0.464286px;
  text-transform: uppercase;

  color: #9e9ca7;
`;
