import React from 'react';
import styled from 'styled-components';

import Video from '@/components/Video';
import ButtonIcon from '@/components/ButtonIcon';
import Button from '@/components/Button';
import CategoriesValue from '@/components/CategoriesValue';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';
import NoPicture2x from '@/assets/images/no-picture@2x.png';
import { colors } from '@/constants/theme';
import { ProductFull } from '@/typings/model';

interface Props {
  closeModal: () => void;
  product: ProductFull;
  toggleAddToCart: () => void;
  setTag: (tag: string) => void;
  isActive: boolean;
}

function DesktopModal({
  closeModal,
  product,
  toggleAddToCart,
  setTag,
  isActive,
}: Props) {
  return (
    <>
      <Inner>
        <Icon icon={'close'} onClick={closeModal} />
        <>
          <HeaderBlock>
            <HeaderContent>
              <Title>{product.name}</Title>
              <CategoriesValue
                name={product.subcategories
                  .map((subcategory) => subcategory?.name)
                  .join(', ')}
                icon={product.category.icon}
              />
            </HeaderContent>
          </HeaderBlock>
          <ContentBlock>
            <LeftBlock>
              {product.isBasePackage && <Label>Free</Label>}
              <Video
                videoUrl={product.video}
                previewUrl={product.image ? product.image : NoPicture2x}
              />
            </LeftBlock>
            <RightBlock>
              {product.description ? <Info>{product.description}</Info> : null}
              {product.tags ? (
                <TagList>
                  {product.tags.map((tag, index) => (
                    <React.Fragment key={index}>
                      <Tag
                        onClick={() => {
                          setTag(tag);
                        }}
                        key={index}
                      >
                        #{tag}
                      </Tag>{' '}
                    </React.Fragment>
                  ))}
                </TagList>
              ) : null}
              {product.features.length ? (
                <Category>
                  <SubTitle>Features</SubTitle>
                  <Technology>
                    {product.features
                      .map((feature) => feature?.name)
                      .join(', ')}
                  </Technology>
                </Category>
              ) : null}
              {product.platforms.length ? (
                <Category>
                  <SubTitle>Platforms</SubTitle>
                  <Technology>{product.platforms.join(', ')}</Technology>
                </Category>
              ) : null}
              <AddToCard
                onClick={toggleAddToCart}
                variant={'contained'}
                fullWidth={true}
              >
                {isActive ? 'Remove from Cart' : 'Add to Cart'}
              </AddToCard>
            </RightBlock>
          </ContentBlock>
        </>
      </Inner>
    </>
  );
}

export default DesktopModal;

const HeaderBlock = styled.div`
  border-bottom: solid 1px #eceef3;
  padding: 23px 0 18px;
  flex: 1 0 0;
`;

const HeaderContent = styled.div`
  padding: 0 30px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  line-height: 1.25;
  color: ${colors.darkBlue};
  margin: 0 0 14px;
`;

const Icon = styled(ButtonIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Inner = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  z-index: 11;
  min-height: 600px;
  width: 740px;
  border-radius: 20px;
`;

const ContentBlock = styled.div`
  display: flex;
  border-bottom-left-radius: 18px;
  overflow: hidden;
`;

const LeftBlock = styled.div`
  position: relative;
  ${customPlaceholder};
  width: 320px;
  max-height: 496px;
`;

const Label = styled.span`
  top: 20px;
  position: absolute;
  border-top-right-radius: 20.5px;
  border-bottom-right-radius: 20.5px;
  background-image: linear-gradient(to left, #0018ff, #0c0342);
  padding: 4px 10.4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #ffffff;
  z-index: 1;
`;

const RightBlock = styled.div`
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Info = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.darkBlue};
  max-width: 320px;
  margin: 10px 0 20px;
`;

const TagList = styled.ul`
  line-height: 1.43;
  margin-bottom: 20px;
`;
const Tag = styled.li`
  color: #0017fe;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;

const Category = styled.div`
  margin-bottom: 15px;
`;

const SubTitle = styled.div`
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.46px;
  color: #9e9ca7;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Technology = styled.div`
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: ${colors.darkBlue};
`;

const AddToCard = styled(Button)`
  margin: auto 0 0;
`;
